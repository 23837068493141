.container {
    margin: env(--space-16) 0;

    .description {
        color: var(--alpha-400);
    }

    .requirement {
        .text {
            color: var(--error-700);
        }

        .icon {
            color: var(--error-500);
        }

        &[data-success="true"] {

            .text {
                color: var(--success-700);
            }

            .icon {
                color: var(--success-500);
            }
        }
    }
}
