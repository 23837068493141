@import "@/styles/_mixins.scss";

.container {
	position: relative;
	width: 100%;
	height: 100%;
	min-height: 100dvh;

	.logo {
		width: calc(env(--space-200)*1.3);
		height: env(--space-64);
		display: block;
		position: relative;
		object-fit: contain;
		margin-bottom: env(--space-24);
		display: block;

		@include responsiveMinWidth(tablet) {
			display: none;
		}
	}



	.left {
		display: none;

		@include responsiveMinWidth(tablet) {
			width: 50%;
			height: 100%;
			display: flex;
			position: relative;
		}

		.bgImage {


			@include responsiveMinWidth(tablet) {
				width: 50%;
				display: flex;
				position: fixed;
				top: 0;
				bottom: 0;
			}

			img {
				object-fit: cover;
				max-height: 100vh;
				height: 100%;
			}
		}

		.logo {
			display: none;

			@include responsiveMinWidth(tablet) {
				display: block;
				z-index: 100;
				position: fixed;
				align-self: center;
				top: env(--space-48);
			}
		}

		.support {
			display: none;

			@include responsiveMinWidth(tablet) {
				display: flex;
				z-index: 100;
				position: fixed;
				bottom: env(--space-8);
				left: 0;
				right: 50%;
				margin: 0 env(--space-16);
				color: var(--alpha-0);
				flex-direction: row;

				button {
					* {
						color: var(--alpha-0)
					}
				}
			}
		}

	}

	.bannerSignInWrapper {
		width: 100%;

		@include responsiveMinWidth(tablet) {
			padding: env(--space-32) env(--space-48);
			width: 50%;
		}
	}

	.right {
		width: 100%;
		padding: env(--space-24) env(--space-16);

		@include responsiveMinWidth(tablet) {
			position: relative;
			height: 100%;
			display: flex;
			margin: auto 0;
			width: 100%;
			padding: env(--space-24) 0;

		}

		.support {
			display: flex;
			z-index: 100;
			bottom: env(--space-8);
			margin: env(--space-16) 0;
			color: var(--alpha-400);
			flex-direction: column-reverse;

			button {
				* {
					color: var(--alpha-400)
				}
			}

			@include responsiveMinWidth(tablet) {
				display: none;
			}
		}

		.content {
			width: 100%;
			padding: 0 env(--space-16);
			padding-bottom: env(--space-24);

			@include responsiveMinWidth(tablet) {
				margin: auto;
				width: 100%;
				max-width: calc(env(--space-200)*2.4);
			}

		}
	}
}

.error {
	color: var(--error-500);
	width: 100%;
	margin-bottom: env(--space-6);
	text-align: center;
}

.backIcon {
	cursor: pointer;

}

.description {
	color: var(--alpha-400);
}

.tosWrapper {
	margin-bottom: env(--space-24);

	.required {
		color: var(--error-500);
	}

	.link {
		text-decoration: underline;
		color: var(--primary-500);
	}
}

.googleLogo {
	[data-disabled="true"] {
		opacity: .5;
	}
}
