@import "@/styles/_mixins.scss";

.dot {
    background-color: rgba(116, 210, 126, 0.3);
    width: env(--space-40);
    height: env(--space-40);
    border-radius: 50%;
    position: relative;

    @include responsiveMinWidth(phone) {
        width: calc(env(--space-48) + env(--space-8));
        height: calc(env(--space-48) + env(--space-8));
    }

    & span {
        color: var(--success-600)
    }
}
