@import "@/styles/_mixins.scss";

.container {
  border: 1px solid var(--alpha-100);
  border-radius: env(--radius-20);

  padding: env(--space-16) env(--space-24);

  .iconWrapper {
    border: 1px solid var(--alpha-100);
    border-radius: env(--radius-20);
    background-color: var(--alpha-50);
    min-width: env(--space-64);
    height: env(--space-64);
  }

  .infoWrapper {
    @include responsiveMinWidth(tablet) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex: 1;
    }

    .grayText {
      color: var(--alpha-400)
    }

  }
}
